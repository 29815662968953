export function TabbyProductPageSnippetCCI() {
  // noop
}

declare global {
  interface Window {
    /**
     * @deprecated keep for backward capability
     */
    TabbyProductPageSnippetCCI: () => void;
  }
}

window.TabbyProductPageSnippetCCI = TabbyProductPageSnippetCCI;
